import * as React from "react";
import * as ReactDOM from "react-dom";
import Home from "./home";
import Events from "./events";
import News from "./news";
import Shop from "./shop";

const App = () => {
  return <Home />;
};

const onMenu = (e: Event) => {
  const el = e.currentTarget as HTMLElement;
  const menu = el.dataset["menu"];
  let comp = <Home />;
  let banner = document.getElementById("banner");
  let showBanner = true;
  switch (menu) {
    case "events":
      comp = <Events />;
      break;
    case "news":
      comp = <News />;
      break;
    case "shop":
      showBanner = false;
      comp = <Shop />;
      break;
  }
  banner.style.display = showBanner ? "block" : "none";
  ReactDOM.render(comp, document.getElementById("app-container"));
};

const menu = document.querySelectorAll("a.menu");
menu.forEach((m) => m.addEventListener("click", onMenu));

ReactDOM.render(<App />, document.getElementById("app-container"));
