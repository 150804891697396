import * as React from "react";

const Events = () => {
  return (
    <section id="events" className="mt-4 container mx-auto">
      <div className="p-4 bg-white flex flex-col lg:flex-row">
        <img src="images/events.jpg" className="rounded-lg" />
        <div className="flex-1 px-4">
          <p className="uppercase tracking-wide leading-tight font-bold text-3xl text-center mb-3">
            Events
          </p>
          <p>
            We can cater for any kind of event including weddings, private
            parties, sports or company events.
          </p>
          <p className="mt-4 uppercase font-bold text-2xl lg:text-4xl text-center">
            Call: <a href="tel:07733777176">07733 777 176</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Events;
