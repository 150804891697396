const Menu = {
  Margherita: {
    description: "Pizza sauce & mozerella",
    price: [8, 12],
  },
  Hawaiian: {
    description: "Ham & pineapple",
    price: [11, 14],
  },
  Diablo: {
    description: "Pepperoni & Jalapeños",
    price: [11, 14],
  },
  Florence: {
    description: "Spinach, olives & free range egg",
    price: [11, 14],
  },
  "American Classic": {
    description: "Pepperoni, mushrooms & peppers",
    price: [12, 15],
  },
  "La Reine": {
    description: "Ham, mushrooms & olives",
    price: [12, 15],
  },
  Neopolitan: {
    description: "Anchovies, lillyput capers & olives",
    price: [12, 15],
  },
  "The Royal": {
    description: "Ham, mushrooms, olives & free range egg",
    price: [13, 16],
  },
  "Garden Pizza": {
    description: "Sweet peppers, red onions, spinach, mushrooms & olives",
    price: [11, 14],
  },
  Capriciosa: {
    description: "Ham, mushrooms, anchovies, capers & olives",
    price: [14, 17],
  },
  "Carne Di Gusto": {
    description: "Pepperoni, ham & meatballs",
    price: [13, 16],
  },
  Verona: {
    description: "Sun blushed tomato, goats cheese, spinach & olives",
    price: [13, 16],
  },
  "Cajun Chicken": {
    description: "Cajun spiced chicken with red onion & peppers",
    price: [12, 15],
  },
  "Pesto Chicken": {
    description: "Chicken, pine nuts, spinach, mushrooms & pesto",
    price: [13, 16],
  },
  "BBQ Chicken style": {
    description: "BBQ base, chciken, sweetcorn, peppers & red onions",
    price: [13, 16],
  },
  "MAKE YOUR OWN 3 TOPPING": {
    description: "3 Toppings of your choice",
    price: [13, 16],
  },
};

export default Menu;
