import * as React from "react";
import Menu from "./menu";

const Oxford = () => {
  return (
    <section id="bladon" className="w-full lg:w-full lg:mx-auto text-center bg-gray-100">
      <div className="flex items-center flex-col lg:flex-row justify-between bg-orange">
        <div className=" lg:flex-grow">
          <h1 className="text-3xl tracking-wide font-bold uppercase">woodstock</h1>
          <p className="text-2xl font-semibold tracking-wider">Bladon/Woodstock by caravan park</p>
        </div>
        <a href="tel:07733777176" className="text-2xl lg:text-4xl tracking-wide font-extrabold lg:mx-auto bg-orange p-4">
          <i className="fas fa-mobile-alt"></i>
          <span className="ml-2">07733 777 176</span>
        </a>
      </div>
      <div className="mt-5 px-2 lg:px-5 flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:w-1/2">
          <div id="menu">
            <h2 className="mt-5 mb-2 uppercase font-bold text-center text-2xl">menu</h2>
            <table className="table-auto w-full text-left pizzas">
              <thead>
                <tr>
                  <th className="text-center text-2xl text-gray-600">Style</th>
                  <th className="text-right text-2xl text-gray-600">12"</th>
                  <th className="text-right text-2xl text-gray-600">14"</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(Menu).map((m) => {
                  return (
                    <tr>
                      <td>
                        <div className="flex flex-col">
                          <span className="name">{m}</span>
                          <span className="description">{Menu[m].description}</span>
                        </div>
                      </td>
                      <td className="price">
                        {Menu[m].price[0].toLocaleString("en-GB", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "GBP",
                        })}
                      </td>
                      <td className="price">
                        {Menu[m].price[1].toLocaleString("en-GB", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "GBP",
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div id="sides">
            <h2 className="mt-5 mb-2 uppercase font-bold text-center text-2xl">Side orders</h2>
            <table className="w-full table-auto text-left">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th className="text-gray-700 uppercase font-bold text-right">LARGE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="uppercase name font-bold py-1">garlic bread</td>
                  <td className="font-bold text-right">£4.00</td>
                  <td className="font-bold text-right">£5.00</td>
                </tr>
                <tr>
                  <td className="uppercase name font-bold py-1">cheesy garlic bread</td>
                  <td className="font-bold text-right">£5.00</td>
                  <td className="font-bold text-right">£7.00</td>
                </tr>
                <tr>
                  <td className="uppercase name font-bold py-1">extra cheese</td>
                  <td className="font-bold text-right">£2.00</td>
                  <td className="font-bold text-right">£3.00</td>
                </tr>
                <tr>
                  <td className="uppercase name font-bold py-1">extra toppings meat</td>
                  <td className="font-bold text-right">£2.00</td>
                  <td className="font-bold text-right">£3.00</td>
                </tr>
                <tr>
                  <td className="uppercase name font-bold py-1">extra vegetable</td>
                  <td className="font-bold text-right">£1.00</td>
                  <td className="font-bold text-right">£2.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col lg:flex-grow lg:px-3 text-center relative">
          <div className="text-center">
            <iframe
              width="100%"
              height={window.innerWidth > 600 ? 400 : 300}
              id="mapcanvas"
              src="https://maps.google.com/maps?q=OX20%201PT&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              frameborder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
          <div>
            {/* <div
              className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <p>Mon-Tues until the end of July</p>
            </div> */}
            <p className="uppercase font-bold text-2xl">times</p>
            <table className="mt-4 table-auto w-full uppercase text-center">
              <tbody>
                {/* <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">Monday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 8PM</td>
                </tr>
                <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">Tuesday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 8PM</td>
                </tr> */}
                <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">Wednesday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 8PM</td>
                </tr>
                <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">thursday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 8PM</td>
                </tr>
                <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">Friday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 9PM</td>
                </tr>
                <tr className="text-left">
                  <td className="border-b-2 border-b-gray-900 py-2">Saturday</td>
                  <td className="border-b-2 border-b-gray-900">5PM - 9PM</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Oxford;
