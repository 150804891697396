import * as React from "react";
import { useState } from "react";
import Oxford from "./oxford";
import Scotland from "./scotland";
import * as classNames from "classnames";

const styles = {
  location:
    "p-3 border-black rounded border hover:text-white hover:bg-black cursor-pointer",
  location_active:
    "p-3 border-orange rounded border-2 text-white bg-black cursor-none",
};

const Home = () => {
  const [location, setLocation] = useState("oxford");

  const onLocationChange = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLocation(e.currentTarget.dataset["location"]);
  };

  return (
    <>
      <ul className="flex items-center justify-center font-bold text-xl mb-4">
        <li
          className={
            location === "oxford" ? styles.location_active : styles.location
          }
          data-location="oxford"
          onClick={onLocationChange}
        >
          <a>Woodstock</a>
        </li>
        {/* <li
          className={"ml-3 ".concat(
            location === "scotland" ? styles.location_active : styles.location
          )}
          data-location="scotland"
          onClick={onLocationChange}
        >
          <a>Scotland</a>
        </li> */}
      </ul>
      {location === "oxford" ? <Oxford /> : <Scotland />}
    </>
  );
};

export default Home;
