import * as React from "react";
import { useEffect, useRef } from "react";

export default () => {
  const node = useRef<HTMLElement>(null);

  useEffect(() => {
    if (typeof window["twttr"] === "undefined") {
      const twittertimeline = node.current;
      const twitterscript = document.createElement("script");
      twitterscript.src = "//platform.twitter.com/widgets.js";
      twitterscript.async = true;
      twitterscript.id = "twitter-wjs";
      twittertimeline.parentNode.appendChild(twitterscript);
    } else {
      window["twttr"].widgets.load();
    }
    return () => {};
  }, []);

  return (
    <div className="container mx-auto">
      <a
        ref={node}
        className="twitter-timeline"
        href="https://twitter.com/ricopizzashack?ref_src=twsrc%5Etfw"
      >
        Tweets by ricopizzashack
      </a>
    </div>
  );
};
